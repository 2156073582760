import { Box, Container } from '@mui/material';
import { useIntl } from 'react-intl';
import OneTrustCookieSettings from '../../utils/OneTrustCookieSettings/OneTrustCookieSettings';
import FeedbackButton from '../feedback/FeedbackButton';
import BaseLink from '../link/Link';

function Link({ to, children, external }: { to?: string; external?: boolean; children: string }) {
  return (
    <BaseLink
      external={external}
      to={to || ''}
      sx={{
        fontSize: '0.875rem',
        fontWeight: 500,
        textTransform: 'uppercase',
        flex: '0 0 auto',
        cursor: 'pointer',
        color: 'white.100',
        '&:hover': {
          color: 'orange.500',
        },
      }}
    >
      {children}
    </BaseLink>
  );
}

export default function Footer() {
  const { formatMessage } = useIntl();

  return (
    <Box
      component="footer"
      sx={(theme) => ({
        backgroundColor: theme.palette.blue[600],
        color: (theme) => theme.palette.white[100],
      })}
    >
      <Container>
        <Box py={5} m="0 auto" maxWidth={600}>
          <Box py={2} color="white" fontSize="2rem" textAlign="center">
            {formatMessage({ id: 'nav.feedback' })}
          </Box>
          <FeedbackButton />
        </Box>
        <Box
          px={5}
          py={2}
          maxWidth={1200}
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
        >
          <Box
            component="nav"
            aria-label="footer"
            flex="2 1"
            display="flex"
            justifyContent="flex-start"
            flexWrap="wrap"
            gap="16px"
          >
            <Link to="/">{formatMessage({ id: 'nav.home' })}</Link>
            <Link to="/income-qualified">{formatMessage({ id: 'nav.incomeQualified' })}</Link>
            <Link to="/ev-owners">{formatMessage({ id: 'nav.existingEvOwners' })}</Link>
            <Link to="/rates">{formatMessage({ id: 'nav.rates' })}</Link>
            <Link to="/vehicles">{formatMessage({ id: 'nav.vehicles' })}</Link>
            <Link to="/compare">{formatMessage({ id: 'nav.compare' })}</Link>
            <Link to="/incentives">{formatMessage({ id: 'nav.incentives' })}</Link>
            <Link to="/charging-stations">{formatMessage({ id: 'nav.stations' })}</Link>
            <Link to="/faq">{formatMessage({ id: 'nav.faq' })}</Link>
            <Link to="/charging-faq">{formatMessage({ id: 'nav.chargingFaq' })}</Link>
            <Link to="/search">{formatMessage({ id: 'nav.search' })}</Link>
            <Link
              external
              to="https://www.pge.com/en_US/about-pge/company-information/privacy-policy/privacy.page"
            >
              {formatMessage({ id: 'nav.privacy' })}
            </Link>
            <OneTrustCookieSettings />
          </Box>
        </Box>
        <Box
          px={5}
          py={2}
          maxWidth={1200}
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
        >
          <Box flex="1 1" textAlign={{ xs: 'left', md: 'right' }} mt={{ xs: 2, md: 0 }}>
            <div>{formatMessage({ id: 'nav.poweredBy' })}</div>
            <BaseLink
              external={true}
              to="https://www.zappyride.com/legal-notices"
              sx={{
                fontSize: '0.875rem',
                flex: '0 0 auto',
                cursor: 'pointer',
                color: 'white.100',
                '&:hover': {
                  color: 'orange.500',
                },
              }}
            >
              {formatMessage({ id: 'nav.legalNotices' })}
            </BaseLink>
          </Box>
        </Box>
      </Container>
      <Box
        sx={(theme) => ({
          py: 5,
          textAlign: 'center',
          color: theme.palette.grey[300],
          backgroundColor: theme.palette.blue[800],
        })}
      >
        {formatMessage({ id: 'nav.copyright' }, { year: new Date(Date.now()).getFullYear() })}
      </Box>
    </Box>
  );
}
