import { useState, useRef, useCallback } from 'react';
import { transformDealer } from '../../../api/transformers/transformDealer';
import { apiSettings } from '../../../config/api';
import { Dealer } from '../../../types';

const PATH = '/dealers';

const fetchDataFromApi = async (
  { lat, lng, radiusInMi }: { lat: number; lng: number; radiusInMi: number },
  signal: AbortController['signal'],
) => {
  const { url, apiKey } = apiSettings;
  let options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'X-Api-Key': apiKey,
    },
    signal,
  };

  let params = {
    lat: lat.toString(),
    long: lng.toString(),
    distance: radiusInMi.toString(),
  };

  let fullUrl = new URL(url + PATH);
  let searchParams = new URLSearchParams(params);

  fullUrl.search = searchParams.toString();

  const response = await window.fetch(fullUrl.toString(), options);
  const data = await response.json();
  return data;
};

const useDealers = () => {
  const [loading, setLoading] = useState(false);
  const [dealers, setDealers] = useState<Array<Dealer>>([]);
  const [error, setError] = useState(undefined);

  const abortControllerRef = useRef<AbortController | null>(null);

  const fetchDealers = useCallback(
    async ({ lat, lng, radiusInMi }: { lat: number; lng: number; radiusInMi: number }) => {
      if (!lat || !lng || !radiusInMi) return;

      try {
        if (abortControllerRef.current) {
          abortControllerRef.current?.abort();
        }

        abortControllerRef.current = new window.AbortController();
        setLoading(true);

        const data = await fetchDataFromApi(
          {
            lat,
            lng,
            radiusInMi: Math.ceil(radiusInMi),
          },
          abortControllerRef.current.signal,
        );

        abortControllerRef.current = null;
        setDealers(data.dealers.map(transformDealer));
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        setError(error);
        abortControllerRef.current = null;
      }
    },
    [],
  );

  return {
    dealers,
    fetchDealers,
    loading,
    error,
  };
};

export default useDealers;
