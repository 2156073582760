import REPRESENTATIVE_LOAD_PROFILES, {
  YEAR,
} from '../../data/loadProfiles/representativeLoadProfiles';
import type { UserPrefs } from '../../types';
import selectClimateZone from './selectClimateZone';
import { LoadProfile } from '@bellawatt/electric-rate-engine';

export const genLoadProfileKey = (userPrefs: UserPrefs) => {
  const nemKey = userPrefs.hasSolarAtHome ? 'nemy' : 'nemn';
  let rateKey;
  const climateZoneKey = selectClimateZone(userPrefs);

  switch (userPrefs.currentElectricRate) {
    case 'E-1':
      rateKey = 'e1';
      break;
    case 'E-TOU-C':
      rateKey = 'etouc';
      break;
    case 'E-TOU-D':
      rateKey = 'etoud';
      break;
    case 'EV2-A':
      rateKey = 'eva';
      break;
    default:
      rateKey = 'e1';
      console.log(
        `Selected rate does not match available options: ${userPrefs.currentElectricRate}.`,
      );
  }

  return rateKey + '__' + climateZoneKey + '__' + nemKey;
};

const selectRepresentativeLoadProfile = (userPrefs: UserPrefs) => {
  const profileKey = genLoadProfileKey(userPrefs);
  if (profileKey in REPRESENTATIVE_LOAD_PROFILES) {
    return new LoadProfile(REPRESENTATIVE_LOAD_PROFILES[profileKey], { year: YEAR });
  }
  return new LoadProfile([], { year: YEAR });
};

export default selectRepresentativeLoadProfile;
