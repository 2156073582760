import { Box, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import useFilteredVehiclesForRates from '../../../../api/hooks/useFilteredVehiclesForRates';
import isPHEV from '../../../../calculations/vehicle/isPHEV';
import Accordion from '../../../../components/accordion/Accordion';
import Button from '../../../../components/button/Button';
import Card from '../../../../components/card/Card';
import Link from '../../../../components/link/Link';
import Select from '../../../../components/select/Select';
import Slider from '../../../../components/slider/Slider';
import { E_1, E_TOU_C, E_TOU_D } from '../../../../constants/rates';
import { useUserPrefs } from '../../../../context/UserPrefsProvider';
import ELECTRIC_VEHICLE_LOAD_PROFILES from '../../../../data/loadProfiles/electricVehicleLoadProfiles';
import { isRateKey } from '../../../../types';
import { formatAsCents, formatAsDollars } from '../../../../utils/formatters';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import GaTracker from '../../../../utils/GaTracker/GaTracker';

type FiltersParams = {
  usingHistorical: boolean;
  resetHistorical: () => void;
  setHistoricalDataOpen: (open: boolean) => void;
  showHistoricalOptions: boolean;
};

export default function Filters({
  usingHistorical,
  resetHistorical,
  setHistoricalDataOpen,
  showHistoricalOptions,
}: FiltersParams) {
  showHistoricalOptions = showHistoricalOptions || usingHistorical;

  const { formatMessage } = useIntl();

  const { userPrefs, setUserPrefs } = useUserPrefs();
  const { makes, models, selectedVehicle } = useFilteredVehiclesForRates();

  const handleClickUseHistorical = () => {
    GaTracker.trackEvent({
      category: 'Share My Data',
      action: 'Opened Share My Data Modal',
    });

    setHistoricalDataOpen(true);
  };

  return (
    <Box display="flex" flexDirection="column" gap="1rem">
      <Card>
        <Accordion
          startOpen
          title={
            <Typography variant="h5" mb="0.5rem">
              {formatMessage({ id: 'rates.filters.basicFilters.title' })}
              <Tooltip
                title={formatMessage({ id: 'rates.filters.basicFilters.tooltip' })}
                placement="right"
              >
                <InfoOutlinedIcon fontSize="inherit" sx={{ marginLeft: '8px' }} />
              </Tooltip>
            </Typography>
          }
        >
          <Box display="flex" flexDirection="column" gap="1rem">
            <Select
              value={userPrefs.currentElectricRate}
              label={formatMessage({ id: 'rates.filters.basicFilters.currentRateLabel' })}
              options={[
                {
                  label: `E-TOU-C (${formatMessage({ id: 'common.default' })})`,
                  value: E_TOU_C,
                },
                {
                  label: 'E-1',
                  value: E_1,
                },
                {
                  label: 'E-TOU-D',
                  value: E_TOU_D,
                },
              ]}
              onChange={(value) => isRateKey(value) && setUserPrefs({ currentElectricRate: value })}
            />
            <Select
              value={selectedVehicle?.make || userPrefs.vehicleMakeFilter}
              label={formatMessage({ id: 'rates.filters.basicFilters.evMakeLabel' })}
              options={makes.map((make) => {
                return {
                  label: make,
                  value: make,
                };
              })}
              onChange={(value) => {
                if (value !== userPrefs.vehicleMakeFilter) {
                  setUserPrefs({
                    vehicleMakeFilter: value,
                    comparedVehicleId1: undefined,
                  });
                }
              }}
            />
            <Select
              value={userPrefs.comparedVehicleId1 || ''}
              label={formatMessage({ id: 'rates.filters.basicFilters.evModelLabel' })}
              disabled={!userPrefs.vehicleMakeFilter || userPrefs.vehicleMakeFilter === 'All'}
              options={Object.keys(models).map((key) => {
                return {
                  label: models[key],
                  value: key,
                };
              })}
              onChange={(value) => setUserPrefs({ comparedVehicleId1: value })}
            />
            <Slider
              value={userPrefs.milesDrivenAnnually}
              label={formatMessage({ id: 'rates.filters.basicFilters.milesPerYearLabel' })}
              min={1000}
              max={100000}
              step={1000}
              valueSuffix={` ${formatMessage({
                id: 'rates.wizard.annualMiles.milesPerYearSuffix',
              })}`}
              onChange={(value) => setUserPrefs({ milesDrivenAnnually: value })}
            />
            <Select
              value={userPrefs.vehicleChargingPattern}
              label={formatMessage({ id: 'rates.filters.basicFilters.chargingPatternLabel' })}
              options={Object.keys(ELECTRIC_VEHICLE_LOAD_PROFILES).map((profileKey) => {
                return {
                  label: formatMessage({
                    id: `common.chargingPeriods.${profileKey}`,
                  }),
                  value: profileKey,
                };
              })}
              onChange={(value) => setUserPrefs({ vehicleChargingPattern: value })}
            />
          </Box>
        </Accordion>
      </Card>
      <Card>
        <Accordion
          startOpen
          title={
            <Typography variant="h5" mb="0.5rem">
              {formatMessage({ id: 'rates.filters.homeAssumptions.title' })}
            </Typography>
          }
        >
          <Box display="flex" flexDirection="column" gap="1rem">
            <Slider
              value={userPrefs.homeElectricBillMonthlyAvg}
              label={formatMessage({
                id: 'rates.filters.homeAssumptions.monthlyElectricBillLabel',
              })}
              valueSuffix={formatMessage({ id: 'rates.filters.homeAssumptions.perMonth' })}
              valueFormatter={formatAsDollars}
              min={25}
              max={1500}
              step={5}
              disabled={usingHistorical}
              onChange={(value) => setUserPrefs({ homeElectricBillMonthlyAvg: value })}
            />
            <Select
              value={userPrefs.hasCareDiscount ? 'yes' : 'no'}
              label={formatMessage({ id: 'rates.filters.homeAssumptions.careDiscount' })}
              options={[
                {
                  label: formatMessage({ id: 'rates.filters.yes' }),
                  value: 'yes',
                },
                {
                  label: formatMessage({ id: 'rates.filters.no' }),
                  value: 'no',
                },
              ]}
              onChange={(value) => setUserPrefs({ hasCareDiscount: value === 'yes' })}
              disabled={usingHistorical}
            />
            <Typography sx={{ fontWeight: 'normal', fontSize: '0.8rem' }}>
              {formatMessage(
                { id: 'rates.filters.homeAssumptions.careDiscountDetails' },
                {
                  careLink: (
                    <Link
                      to="https://www.pge.com/en_US/residential/save-energy-money/help-paying-your-bill/longer-term-assistance/care/care.page"
                      external
                    >
                      here
                    </Link>
                  ),
                },
              )}
            </Typography>
            <Select
              value={userPrefs.hasSolarAtHome ? 'yes' : 'no'}
              label={formatMessage({ id: 'rates.filters.homeAssumptions.solarAtHomeLabel' })}
              options={[
                {
                  label: formatMessage({ id: 'rates.filters.yes' }),
                  value: 'yes',
                },
                {
                  label: formatMessage({ id: 'rates.filters.no' }),
                  value: 'no',
                },
              ]}
              onChange={(value) => setUserPrefs({ hasSolarAtHome: value === 'yes' })}
            />
            <Slider
              label={formatMessage({ id: 'rates.solarInputs.powerLabel' })}
              labelTooltip={formatMessage({ id: 'rates.solarInputs.powerLabelTooltip' })}
              valueSuffix={formatMessage({ id: 'rates.solarInputs.powerSuffix' })}
              min={100}
              max={500}
              step={50}
              value={userPrefs.solarPanelPower}
              onChange={(value) => setUserPrefs({ solarPanelPower: value })}
              disabled={!userPrefs.hasSolarAtHome}
            />
            <Slider
              label={formatMessage({ id: 'rates.solarInputs.countLabel' })}
              labelTooltip={formatMessage({ id: 'rates.solarInputs.countLabelTooltip' })}
              valueSuffix={formatMessage({ id: 'rates.solarInputs.countSuffix' })}
              min={0}
              max={50}
              step={1}
              value={userPrefs.solarPanelCount}
              onChange={(value) => setUserPrefs({ solarPanelCount: value })}
              disabled={!userPrefs.hasSolarAtHome}
            />
            <Typography sx={{ fontWeight: 'normal', fontSize: '0.8rem' }}>
              {formatMessage(
                { id: 'rates.filters.homeAssumptions.solarAtHomeDetails' },
                {
                  link: (
                    <Link
                      to="https://www.pge.com/en_US/residential/solar-and-vehicles/options/option-overview/how-to-get-started/how-to-get-started.page"
                      external
                    >
                      {formatMessage({
                        id: 'rates.filters.homeAssumptions.solarAtHomeDetailsLink',
                      })}
                    </Link>
                  ),
                },
              )}
            </Typography>
            {showHistoricalOptions && (
              <>
                {!usingHistorical && (
                  <Button variant="outlined" onClick={handleClickUseHistorical}>
                    {formatMessage({ id: 'rates.filters.homeAssumptions.usingHistoricalButton' })}
                  </Button>
                )}
                {usingHistorical && (
                  <Button variant="outlined" onClick={resetHistorical}>
                    {formatMessage({
                      id: 'rates.filters.homeAssumptions.stopUsingHistoricalButton',
                    })}
                  </Button>
                )}
                <Typography sx={{ fontWeight: 'normal', fontSize: '0.8rem' }}>
                  {formatMessage({ id: 'rates.filters.homeAssumptions.usingHistoricalDisclaimer' })}
                </Typography>
              </>
            )}
          </Box>
        </Accordion>
      </Card>
      <Card>
        <Accordion
          startOpen
          title={
            <Typography variant="h5" mb="0.5rem">
              {formatMessage({ id: 'rates.filters.drivingAssumptions.title' })}
            </Typography>
          }
        >
          <Box display="flex" flexDirection="column" gap="1rem">
            {!!selectedVehicle && isPHEV(selectedVehicle) && (
              <Slider
                value={userPrefs.electricMilesPortionForPhev}
                label={formatMessage({ id: 'rates.filters.drivingAssumptions.phevPortionLabel' })}
                valueFormatter={(value) =>
                  formatMessage(
                    { id: 'rates.filters.phevPortion' },
                    {
                      elec: value,
                      gas: 100 - value,
                    },
                  )
                }
                min={0}
                max={100}
                step={1}
                onChange={(value) => setUserPrefs({ electricMilesPortionForPhev: value })}
              />
            )}
            <Slider
              value={userPrefs.gasolinePriceInCentsPerGal}
              label={formatMessage({ id: 'rates.filters.drivingAssumptions.gasPriceLabel' })}
              valueFormatter={(value) =>
                formatMessage({ id: 'rates.filters.gasCost' }, { val: formatAsCents(value / 100) })
              }
              min={0}
              max={1000}
              step={5}
              onChange={(value) => setUserPrefs({ gasolinePriceInCentsPerGal: value })}
            />
            <Slider
              value={userPrefs.equivalentMilesPerGallon}
              label={formatMessage({
                id: 'rates.filters.drivingAssumptions.fossilEfficiencyLabel',
              })}
              valueSuffix={` ${formatMessage({ id: 'rates.filters.mpg' })}`}
              min={0}
              max={70}
              step={1}
              onChange={(value) => setUserPrefs({ equivalentMilesPerGallon: value })}
            />
            <Slider
              value={userPrefs.publicChargingPercentage}
              label={formatMessage({
                id: 'rates.filters.drivingAssumptions.publicChargingPortionLabel',
              })}
              valueSuffix="%"
              min={0}
              max={100}
              step={1}
              onChange={(value) => setUserPrefs({ publicChargingPercentage: value })}
            />
            <Select
              value={`${userPrefs.publicChargingCostInCentsPerKwh}`}
              label={formatMessage({
                id: 'rates.filters.drivingAssumptions.publicChargingCostLabel',
              })}
              options={[
                {
                  label: formatMessage({ id: 'rates.filters.drivingAssumptions.optionFree' }),
                  value: '0',
                },
                {
                  label: formatMessage({ id: 'rates.filters.drivingAssumptions.optionLow' }),
                  value: '20',
                },
                {
                  label: formatMessage({ id: 'rates.filters.drivingAssumptions.optionMedium' }),
                  value: '40',
                },
                {
                  label: formatMessage({ id: 'rates.filters.drivingAssumptions.optionHigh' }),
                  value: '60',
                },
              ]}
              onChange={(value) =>
                setUserPrefs({ publicChargingCostInCentsPerKwh: parseInt(value) })
              }
            />
          </Box>
        </Accordion>
      </Card>
    </Box>
  );
}
