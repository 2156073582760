import { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import useMapBounds from '../../hooks/useMapBounds';
import useSelectedStation from '../../hooks/useSelectedStation';
import useChargingStationsNearRoute from '../../hooks/useChargingStationsNearRoute';
import type { ChargingStation, Coords, PspsEvent } from '../../../../types';
import MapLegend from '../mapLegend/MapLegend';
import MapControlPanel from '../mapControlPanel/MapControlPanel';
import BaseGoogleMap from '../baseGoogleMap/BaseGoogleMap';
import ChargingStationsList from '../chargingStationsList/ChargingStationsList';
import { Box } from '@mui/material';
import GaTracker from '../../../../utils/GaTracker/GaTracker';
import isSuperChargingStation from '../../../../utils/predicates/isSuperChargingStation';
import setupGoogleMapsAutocomplete from '../../../../utils/setupGoogleMapsAutocomplete';
import useGeoJsonUrls from '../../hooks/useGeojsonUrls';
import PspsEventLegend from '../pspsEventLegend/PspsEventLegend';
import Button from '../../../button/Button';
import Switch from '../../../switch/Switch';
import ChargingStationsMapMarker from '../chargingStationsMapMarker/ChargingStationsMapMarker';
import TextInput from '../../../textInput/TextInput';
import Link from '../../../link/Link';
import { useIntl } from 'react-intl';

const overviewPathAsLinestring = (overviewPath: Array<Coords>) => {
  const coordsAsText = overviewPath.map((coords) => `${coords.lng} ${coords.lat}`).join(', ');
  return `LINESTRING(${coordsAsText})`;
};

const TravelRouteMap = ({
  chargingStationsFilterFn,
  isVisible = true,
  canIgnoreLowPowerStations = true,
  pspsEvents = [],
  defaultStartAddress = 'San Francisco, CA, USA',
  defaultDestinationAddress = 'Oakland, CA, USA',
}: {
  chargingStationsFilterFn?: (station: ChargingStation) => boolean;
  isVisible?: boolean;
  canIgnoreLowPowerStations?: boolean;
  pspsEvents?: Array<PspsEvent>;
  defaultStartAddress?: string;
  defaultDestinationAddress?: string;
}) => {
  const { formatMessage } = useIntl();
  const [startAddress, setStartAddress] = useState(defaultStartAddress);
  const [destinationAddress, setDestinationAddress] = useState(defaultDestinationAddress);
  const [isFetchingRoute, setIsFetchingRoute] = useState(false);
  const [routeError, setRouteError] = useState<string>();
  const [isIgnoringLowPowerStations, setIsIgnoringLowPowerStations] = useState<boolean>(
    !!canIgnoreLowPowerStations,
  );
  const [routeDistanceInMeters, setRouteDistanceInMeters] = useState<number>();

  const directionsServiceRef = useRef<google.maps.DirectionsService | null>(null);
  const directionsRendererRef = useRef<google.maps.DirectionsRenderer | null>(null);
  const autocompleteStartLocationRef = useRef<google.maps.places.Autocomplete | null>(null);
  const autocompleteEndLocationRef = useRef<google.maps.places.Autocomplete | null>(null);

  const { registerMapBoundsListeners, filterWithinBounds } = useMapBounds();

  const {
    chargingStations,
    fetchChargingStations,
    error: chargingStationsError,
  } = useChargingStationsNearRoute();

  const { selectedStation, selectStation, deselectStation } = useSelectedStation(chargingStations);

  const registerGeojsonUrlMap = useGeoJsonUrls(pspsEvents.flatMap((event) => event.fileUrls));

  const onCompleteStartLocation = () => {
    const address = autocompleteStartLocationRef.current?.getPlace().formatted_address;
    address && setStartAddress(address);
  };

  const onCompleteEndLocation = () => {
    const address = autocompleteEndLocationRef.current?.getPlace().formatted_address;
    address && setDestinationAddress(address);
  };

  const saveMapRefs = (map: google.maps.Map) => {
    directionsServiceRef.current = new google.maps.DirectionsService();
    directionsRendererRef.current = new google.maps.DirectionsRenderer();

    autocompleteStartLocationRef.current = setupGoogleMapsAutocomplete(
      'travel-route-map-start-location',
      onCompleteStartLocation,
    );
    autocompleteEndLocationRef.current = setupGoogleMapsAutocomplete(
      'travel-route-map-end-location',
      onCompleteEndLocation,
    );

    directionsRendererRef.current.setMap(map);
    registerMapBoundsListeners(map);
    fetchRoute(false);

    registerGeojsonUrlMap(map);
  };

  const isSubmittingAllowed = () => !isFetchingRoute && startAddress && destinationAddress;

  const fetchRoute = (isTrackingEvent = true) => {
    setIsFetchingRoute(true);
    setRouteError(undefined);
    setRouteDistanceInMeters(undefined);
    if (isTrackingEvent) {
      GaTracker.trackEvent({
        category: 'Maps',
        action: 'Searched Travel Route',
      });
    }

    directionsServiceRef.current?.route(
      {
        origin: startAddress,
        destination: destinationAddress,
        travelMode: google.maps.TravelMode['DRIVING'],
      },
      (response, status) => {
        if (response && status === 'OK') {
          directionsRendererRef.current?.setDirections(response);

          if (response.routes && response.routes.length > 0) {
            // See https://developer.nrel.gov/docs/transportation/alt-fuel-stations-v1/nearby-route/
            const linestring = overviewPathAsLinestring(
              response.routes[0].overview_path.map((path) => ({
                lat: path.lat(),
                lng: path.lng(),
              })),
            );

            fetchChargingStations({ route: linestring });
            const distanceInMeters = response.routes[0].legs.reduce(
              (totalDistance, leg) => totalDistance + (leg?.distance?.value || 0),
              0,
            );
            setRouteDistanceInMeters(distanceInMeters);
          }
        } else {
          setRouteError('Error fetching route');
        }
        setIsFetchingRoute(false);
      },
    );
  };

  const visibleChargingStations = filterWithinBounds(chargingStations).filter((station) =>
    isIgnoringLowPowerStations ? isSuperChargingStation(station) : true,
  );
  const filteredChargingStations = chargingStationsFilterFn
    ? visibleChargingStations.filter(chargingStationsFilterFn)
    : visibleChargingStations;

  return (
    <Box display="flex" flexDirection="column" gap="1.75rem">
      <Box
        display="flex"
        gap="1rem"
        flexDirection={{ xs: 'column', sm: 'row' }}
        justifyContent="space-between"
        alignItems={{ xs: 'stretch', sm: 'flex-end' }}
      >
        <Box flex="1 1">
          <TextInput
            fullWidth
            label={formatMessage({ id: 'chargerDetails.routeMap.startLocationLabel' })}
            id="travel-route-map-start-location"
            onChange={(value) => setStartAddress(value)}
            value={startAddress}
            placeholder="Start Location"
            disabled={isFetchingRoute}
            onKeyDown={(event) => {
              if (event.key === 'Enter' && isSubmittingAllowed()) {
                fetchRoute();
              }
            }}
          />
        </Box>
        <Box flex="1 1">
          <TextInput
            fullWidth
            label={formatMessage({ id: 'chargerDetails.routeMap.endLocationLabel' })}
            id="travel-route-map-end-location"
            onChange={(value) => setDestinationAddress(value)}
            value={destinationAddress}
            placeholder="End Location"
            disabled={isFetchingRoute}
            onKeyDown={(event) => {
              if (event.key === 'Enter' && isSubmittingAllowed()) {
                fetchRoute();
              }
            }}
          />
        </Box>
        <Box>
          <Button
            sx={{ width: { xs: '100%', sm: 'initial' } }}
            onClick={() => fetchRoute()}
            disabled={!isSubmittingAllowed()}
          >
            {formatMessage({ id: 'chargerDetails.routeMap.mapRouteButton' })}
          </Button>
        </Box>
        {canIgnoreLowPowerStations && (
          <Box pb="0.5rem">
            <Switch
              checked={isIgnoringLowPowerStations}
              label={formatMessage({ id: 'chargerDetails.routeMap.highPoweredStationsOnly' })}
              onChange={() =>
                setIsIgnoringLowPowerStations((currentValue) => {
                  GaTracker.trackEvent({
                    category: 'Maps',
                    action: "Toggled 'High Power Stations Only'",
                    label: currentValue ? 'All Stations' : 'High Power Only',
                  });
                  return !currentValue;
                })
              }
            />
          </Box>
        )}
        {chargingStationsError && (
          <p>{formatMessage({ id: 'chargerDetails.routeMap.stationFetchError' })}</p>
        )}
        {routeError && <p>{formatMessage({ id: 'chargerDetails.routeMap.routeCreateError' })}</p>}
      </Box>
      <Box display="flex" flexWrap="wrap" justifyContent="center" gap="1rem">
        <Box
          flex="1 1"
          minWidth="270px"
          position="relative"
          display="flex"
          flexWrap="wrap"
          gap="1rem"
        >
          {isVisible && (
            <BaseGoogleMap onLoad={saveMapRefs}>
              {filteredChargingStations.map((station) => (
                <ChargingStationsMapMarker
                  key={station.id}
                  station={station}
                  selected={selectedStation?.id === station.id}
                  onMouseEnter={() => selectStation(station.id)}
                  onClick={() => deselectStation()}
                  lat={station.lat}
                  lng={station.lng}
                />
              ))}
            </BaseGoogleMap>
          )}
          {routeDistanceInMeters && (
            <MapLegend>
              <Box>
                {formatMessage(
                  { id: 'chargerDetails.routeMap.routeDistance' },
                  { distanceInMiles: Math.ceil(routeDistanceInMeters / 1609) },
                )}
              </Box>
            </MapLegend>
          )}
        </Box>
        <Box
          width={{ xs: '100%', sm: '240px' }}
          display="flex"
          flexDirection="column"
          gap="1.75rem"
        >
          <MapControlPanel
            selectedStation={selectedStation}
            chargingStations={filteredChargingStations}
          >
            {pspsEvents.length > 0 && <PspsEventLegend />}
          </MapControlPanel>
        </Box>
      </Box>
      <p>
        {formatMessage(
          { id: 'maps.availabilityMap.disclaimer' },
          {
            link: (
              <Link
                to="https://afdc.energy.gov/stations#/station/new"
                external
                sx={{
                  fontWeight: 'normal',
                  textDecoration: 'underline',
                }}
              >
                here
              </Link>
            ),
          },
        )}
      </p>
      <Box>
        <ChargingStationsList chargingStations={filteredChargingStations} />
      </Box>
    </Box>
  );
};

TravelRouteMap.propTypes = {
  chargingStationsFilterFn: PropTypes.func,
  isVisible: PropTypes.bool,
  canIgnoreLowPowerStations: PropTypes.bool,
  pspsEvents: PropTypes.array,
};

export default TravelRouteMap;
