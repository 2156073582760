const DEV_URL = 'https://app.beta.zappyride.com';
const DEV_API_TOKEN = 'de30c21e7df16ae8f7661f8a147fbd9436';
const REACT_APP_STAGING_TOKEN =
  'eyJvcmciOiI2NjRmNjcwY2FiY2FmYzAwMDE0MDAyZjkiLCJpZCI6IjVjNTRiNjg5OWIxNDRlYzI5OTc5MGE4NzM1MTA3MDhlIiwiaCI6Im11cm11cjEyOCJ9';
const REACT_APP_PRODUCTION_TOKEN =
  'eyJvcmciOiI2NDM4MGYxNGFkZGJiYTAwMDE4MWYwMTEiLCJpZCI6IjhhNWVmMjhkMjM0OTQ4MDRiMGRhODI1MTM1ZjE2NWRhIiwiaCI6Im11cm11cjEyOCJ9';

const apiKey =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? REACT_APP_PRODUCTION_TOKEN
    : REACT_APP_STAGING_TOKEN;

export const apiSettings = {
  url: process.env.REACT_APP_BASE_API_HOST || DEV_URL,
  apiKey: apiKey || DEV_API_TOKEN,
};
